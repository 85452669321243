const Footer = () => {
    return (
        <footer className="wow-footer py-5">
            <div className="container">
            <div className="row">
                <div className="col-12 d-flex justify-content-between flex-column flex-md-row">
                    <p className="text-center text-md-start">&copy; Copyright <span className="copyright-year">{new Date().getFullYear()}</span> ProAdvisor Drivers</p>
                    <a href="https://proadvisorcoach.com/" target="_blank" className="text-center text-md-start pt-3 pt-md-0">
                        <img src="/powered.svg" alt="ProAdvisorCoach Footer Logo" />
                    </a>
                </div>
            </div>
            </div>
        </footer>
    )
}

export default Footer